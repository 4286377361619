import { Divider } from "@chakra-ui/react"
import React, { useMemo } from "react"
import { DebugLayout } from "../../components/debug/DebugLayout"
import { Json } from "../../components/Json"
import { Table } from "../../components/Table"

export default function ReactTablePage() {
  const columns = [
    {
      Header: "Word",
      accessor: "word",
    },
    {
      Header: "Length",
      accessor: "length",
    },
  ]

  const data = useMemo(() => {
    return "the quick brown fox jumped over the lazy dog THE QUICK BROWN FOX JUMPED OVER THE LAZY DOG"
      .split(" ")
      .map(word => ({
        word,
        length: word.length,
      }))
  }, [])

  return (
    <DebugLayout title="React Table">
      <Table columns={columns} data={data} />
      <pre>
        <b>columns</b>
      </pre>
      <Json>{columns}</Json>
      <Divider />
      <pre>
        <b>data</b>
      </pre>
      <Json>{data}</Json>
    </DebugLayout>
  )
}
